/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.iconImg {
  width: 20px;
  height: 20px;
}
.menuStyle {
  height: calc(100vh - 110px);
  overflow-y: scroll;
}
.menuStyle::-webkit-scrollbar {
  width: 0px !important;
  display: none;
}
