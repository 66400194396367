/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/**
 cover目录用于样式覆盖
*/
.ant-btn-primary {
  border-color: #AB282D;
  background: linear-gradient(270deg, #AB282D 0%, #FD7585 100%);
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: linear-gradient(270deg, #AB282D 0%, #FD7585 100%);
  border-color: #AB282D;
}
.ant-btn-primary:active {
  color: #fff;
  background: linear-gradient(270deg, #AB282D 0%, #FD7585 100%);
  border-color: #AB282D;
}
.ant-table-cell .ant-space-item span {
  color: #AB282D;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #AB282D !important;
  border-color: #AB282D !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #AB282D !important;
}
.ant-radio-inner::after {
  background: linear-gradient(270deg, #AB282D 0%, #FD7585 100%);
}
.ant-switch-checked {
  background: linear-gradient(270deg, #AB282D 0%, #FD7585 100%);
}
.ant-pagination-item-active {
  border-color: #AB282D !important;
}
.ant-pagination-item-active a {
  color: #AB282D !important;
}
.ant-layout-sider-zero-width-trigger {
  background: #000 !important;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.etc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.etc-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.cp {
  cursor: pointer;
}
.cm {
  cursor: not-allowed;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.ta-r {
  text-align: right;
}
.lh-18 {
  line-height: 18px;
}
.flex {
  display: flex;
}
.fxc {
  justify-content: center;
}
.fyc {
  align-items: center;
}
.fxsb {
  justify-content: space-between;
}
.fw-wp {
  flex-wrap: wrap;
}
.fw-no {
  flex-wrap: nowrap;
}
.fg-1 {
  flex-grow: 1;
}
.fxr {
  justify-content: flex-end;
}
.bd-1 {
  border: 1px solid #ddd;
}
.br-4 {
  border-radius: 4px;
}
.c1 {
  color: #F00009;
}
.c2 {
  color: #02A7F0;
}
.c3 {
  color: rgba(0, 0, 0, 0.44);
}
.btn-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
a {
  color: #F00009;
}
a:hover {
  color: #F00009;
  opacity: 0.7;
}
.reset-dropdown .ant-cascader-menu {
  height: 300px!important;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
li {
  margin: 0;
}
.search_wrap {
  display: flex;
  flex-wrap: wrap;
}
.search_wrap > li {
  margin-right: 10px;
  margin-bottom: 10px;
}
.search_wrap .ant-form-item {
  margin-bottom: 0;
}
html,
body,
#root {
  height: 100%;
}
.textTip {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: linear-gradient(270deg, #AB282D 0%, #FD7585 100%);
}
