@import "~antd/lib/style/themes/default.less";

.ant-btn-primary {
  // background: #ff4d4f;
  border-color: #AB282D;
  background: linear-gradient(270deg, #AB282D 0%, #FD7585 100%);;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: linear-gradient(270deg, #AB282D 0%, #FD7585 100%);;
  border-color: #AB282D;
}

.ant-btn-primary:active {
  color: #fff;
  background: linear-gradient(270deg, #AB282D 0%, #FD7585 100%);;
  border-color: #AB282D;
}

.ant-table-cell .ant-space-item {
  span {
    color: #AB282D;

  }

}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #AB282D !important;
  border-color: #AB282D !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #AB282D !important;
}

.ant-radio-inner::after {
  background: linear-gradient(270deg, #AB282D 0%, #FD7585 100%);;
}

.ant-switch-checked {
  background: linear-gradient(270deg, #AB282D 0%, #FD7585 100%);;
}


.ant-pagination-item-active {
  border-color: #AB282D !important;

  a {
    color: #AB282D !important;
  }
}

.ant-layout-sider-zero-width-trigger {
  background: #000 !important;
}