/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.component {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #AB282D !important;
  background-position: center 110px;
  background-size: 100%;
}
.card {
  width: 450px;
  background: #000;
  border: 0;
  border-radius: 5px;
}
.logo {
  width: 230px;
  height: 64px;
  margin: 0 auto;
  margin-bottom: 40px;
}
