/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
:export {
  tablePaddingVertical: 16px;
  tablePaddingHorizontal: 16px;
  tablePaddingVerticalmd: 12px;
  tablePaddingHorizontalmd: 8px;
  tablePaddingVerticalsm: 8px;
  tablePaddingHorizontalsm: 8px;
}
.header {
  padding: 0px 0px 8px 0px;
  background-color: #ffffff;
}
.columnLink {
  color: #AB282D;
  cursor: pointer;
}
