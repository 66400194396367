/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.operation_button {
  margin-right: 8px;
  min-width: 88px;
}
.operation_button:last-child {
  margin-right: 0;
}
